import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["graphsContainer", "newsContainer", "newsExpandButton", "newsCloseButton", "newsImage", "newsHeight",
					"resourcesContainer", "tsaContainer", "tsaExpandButton", "tsaCloseButton",
					"videosContainer", "videosExpandButton", "videosCloseButton", "videosHeight", "mainVideo", "videoCarousel"];
	static classes = [ "newsImageSize" ]

	expandTsasContainer(){
		this.tsaContainerTarget.classList.add("xl:col-span-5");
		this.tsaContainerTarget.classList.remove("xl:col-span-3");

		this.newsContainerTarget.classList.add("hidden");
		this.resourcesContainerTarget.classList.add("hidden");

		this.tsaExpandButtonTarget.classList.remove("xl:block");
		this.tsaCloseButtonTarget.classList.remove("hidden");
	}

	shrinkTsasContainer(){
		this.tsaContainerTarget.classList.remove("xl:col-span-5");
		this.tsaContainerTarget.classList.add("xl:col-span-3");

		this.newsContainerTarget.classList.remove("hidden");
		this.resourcesContainerTarget.classList.remove("hidden");

		this.tsaExpandButtonTarget.classList.add("xl:block");
		this.tsaCloseButtonTarget.classList.add("hidden");
	}

	expandNewsContainer(){
		this.newsContainerTarget.classList.add("xl:col-span-4", "xl:row-span-2", "sm:col-span-2", "sm:row-span-6");
		this.newsContainerTarget.classList.remove("xl:col-span-2", "sm:row-span-3");
		this.newsHeightTarget.classList.remove("sm:h-[250px]", "lg:h-[300px]");
		this.newsHeightTarget.classList.add("sm:h-[540px]", "lg:h-[640px]");

		for(let image of this.newsImageTargets) {
			image.classList.remove("h-11", "w-11", "2xl:w-12", "2xl:h-12");
			image.classList.add("h-16", "w-24");
		}

		this.resourcesContainerTarget.classList.add("hidden");
		this.videosContainerTarget.classList.add("hidden");
		this.graphsContainerTarget.classList.add("hidden");

		this.newsExpandButtonTarget.classList.remove("sm:block");
		this.newsCloseButtonTarget.classList.remove("hidden");
	}

	shrinkNewsContainer(){
		this.newsContainerTarget.classList.remove("xl:col-span-4", "xl:row-span-2", "sm:col-span-2", "sm:row-span-6");
		this.newsContainerTarget.classList.add("xl:col-span-2", "sm:row-span-3");
		this.newsHeightTarget.classList.remove("sm:h-[540px]", "lg:h-[640px]");
		this.newsHeightTarget.classList.add("sm:h-[250px]", "lg:h-[300px]");

		for(let image of this.newsImageTargets) {
			image.classList.remove("h-16", "w-24");
			image.classList.add("h-11", "w-11", "2xl:w-12", "2xl:h-12");
		}

		this.resourcesContainerTarget.classList.remove("hidden");
		this.videosContainerTarget.classList.remove("hidden");
		this.graphsContainerTarget.classList.remove("hidden");

		this.newsExpandButtonTarget.classList.add("sm:block");
		this.newsCloseButtonTarget.classList.add("hidden");
	}

	expandVideosContainer(){
		this.videosContainerTarget.classList.add("xl:col-span-4", "xl:row-span-2", "sm:col-span-2", "sm:row-span-6");
		this.videosContainerTarget.classList.remove("xl:col-span-2", "sm:row-span-3");
		this.videosHeightTarget.classList.add("sm:h-[540px]", "lg:h-[640px]");
		this.videosHeightTarget.classList.remove("sm:h-[250px]", "lg:h-[300px]");

		this.resourcesContainerTarget.classList.add("hidden");
		this.newsContainerTarget.classList.add("hidden");
		this.graphsContainerTarget.classList.add("hidden");

		this.videosExpandButtonTarget.classList.remove("sm:block");
		this.videosCloseButtonTarget.classList.remove("hidden");
		
		this.mainVideoTarget.classList.remove("h-full");
		this.mainVideoTarget.classList.add("h-[80%]");
		this.videoCarouselTarget.classList.remove("sm:hidden");
	}

	shrinkVideosContainer(){
		this.videosContainerTarget.classList.remove("xl:col-span-4", "xl:row-span-2", "sm:col-span-2", "sm:row-span-6");
		this.videosContainerTarget.classList.add("xl:col-span-2", "sm:row-span-3");
		this.videosHeightTarget.classList.remove("sm:h-[540px]", "lg:h-[640px]");
		this.videosHeightTarget.classList.add("sm:h-[250px]", "lg:h-[300px]");

		this.resourcesContainerTarget.classList.remove("hidden");
		this.newsContainerTarget.classList.remove("hidden");
		this.graphsContainerTarget.classList.remove("hidden");

		this.videosExpandButtonTarget.classList.add("sm:block");
		this.videosCloseButtonTarget.classList.add("hidden");

		this.mainVideoTarget.classList.remove("h-[80%]");
		this.mainVideoTarget.classList.add("h-full");
		this.videoCarouselTarget.classList.add("sm:hidden");
	}
}
